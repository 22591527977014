/* src/components/Dashboard.css */

/* General dashboard styling */
.dashboard {
    padding: 25px;
    background-color: #f9f9f9;  /* Light gray background for a clean look */
    min-height: 100vh;  /* Ensures the dashboard covers the full screen height */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #333;  /* Text color */
  }
  
  /* Dashboard title styling */
  .dashboard h2 {
    font-size: 24px;
    color: #1d1d1f;  /* Apple's typical dark text color */
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;  /* Center-align the title */
  }
  
  /* Chart container styling */
  .chart-container {
    background-color: #ffffff;  /* White background for a clean, modern look */
    border-radius: 20px;  /* Rounded corners for a modern design */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;  /* Centers the chart container */
    display: flex;
    justify-content: center;
    align-items: center;  /* Centers content inside the container */
  }
  
  /* Chart canvas styling */
  .chart-container canvas {
    width: 100%;
    height: 100%;
  }
  
  /* Hover effects for the container */
  .chart-container:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);  /* Stronger shadow on hover */
    transition: box-shadow 0.3s ease;  /* Smooth transition for the shadow */
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .dashboard h2 {
      font-size: 20px;
    }
  
    .chart-container {
      padding: 15px;
      max-width: 100%;
    }
  }
  