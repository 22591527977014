/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding: 10px;
    align-items: center;
    width: 80vw;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    border: solid;
    border-width: 1px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
    position: fixed;  /* Fixes the navbar to the screen */
    bottom: 0;  /* Positions the navbar at the bottom */
    left: 50%;
    transform: translateX(-50%);
    max-width: 500px;
  }
  
  .navbarItem {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    padding: 10px 20px;
    transition: background-color 0.3s;

  }
  
  .navbarItem:hover {
    background-color: #f9f9f9;
    border-radius: 50px;
  }
  