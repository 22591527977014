/* src/components/Login.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .facebook-login-button {
    background-color: #4267B2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .facebook-login-button:hover {
    background-color: #365899;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  